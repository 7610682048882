<template>
  <div>
    <NavHeader></NavHeader>
    <div class="login_page flex_column">
      <div class="content">
        <div class="mid flex_row">
          <div class="text">账号:</div>
          <el-input
            class="flex_1"
            placeholder="手机号码"
            v-model="form.username"
            size="mini"
            clearable
          >
          </el-input>
        </div>
        <div class="mid flex_row">
          <div class="text">新密码:</div>
          <el-input
            class="flex_1"
            placeholder="请输入密码"
            v-model="form.password"
            size="mini"
            clearable
          >
          </el-input>
        </div>

        <div class="mid flex_row">
          <div class="text">确认密码:</div>
          <el-input
            class="flex_1"
            placeholder="请输入密码"
            v-model="form.agpassword"
            size="mini"
            clearable
          >
          </el-input>
        </div>
        <div class="mid flex_row">
          <div class="text">验证码:</div>
          <div class="flex_row flex_1">
            <el-input
              v-model="form.code"
              size="mini"
              placeholder="验证码"
              clearable
              style="width: 100%; margin-right: 5px"
            ></el-input>
            <el-button
              size="mini"
              type="warning"
              style="margin-left: 5px; width: 100%"
            >
              获取验证码
            </el-button>
          </div>
        </div>
        <div class="mid flex_row" style="height: 50px; justify-content: center;margin-top:30px">
          <el-button size="mini" type="warning">确认</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 忘记密码页面
import NavHeader from "../components/NavHeader.vue";
export default {
  name: "index",
  data() {
    return {
      input: "",
      checked: false,
      form: {
        username: "",
        password: "",
        agPassword: "",
        code: "",
      },
    };
  },
  components: {
    NavHeader,
  },
  methods: {
    login() {
      this.axios
        .get("/user/login", {
          params: {
            name: "222",
          },
        })
        .then((res) => {
          this.resa = res;
          // console.log(this.resa);
          this.$store.dispatch("saveUserName", this.resa.username);
          // console.log(this.$store.state.username);
        });
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scope>
.login_page {
  width: 100%;
  height: calc(100vh - 63px);
  background: url("../assets/image/02.jpg") no-repeat center;
  background-size: 100% 100%;
  .content {
    margin-bottom: 100px;
    width: 282px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    .mid {
      margin: 10px 0;
      .text {
        font-size: 14px;
        line-height: 30px;
        color: #000000;
        width: 80px;
        text-align: right;
        margin-right: 10px;
      }
    }
  }
}
</style>
